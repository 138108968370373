import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SessionService } from 'src/app/core/auth/session.service';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService {
  constructor(private sessionService: SessionService) {}

  public reloadGLobalCountryProjects: EventEmitter<void> =
    new EventEmitter<void>();

  private currencyCode$: BehaviorSubject<string> = new BehaviorSubject<string>(
    this.sessionService.getItem('currencycode') ?? 'MXN'
  );
  private currencySymbol$: BehaviorSubject<string> =
    new BehaviorSubject<string>(
      this.sessionService.getItem('currencysymbol') ?? '$'
    );

  get currencyCodeGet(): string {
    return this.currencyCode$.value;
  }

  get currencySymbolGet(): string {
    return this.currencySymbol$.value;
  }

  set currencyCodeSet(currencyCode: string) {
    this.currencyCode$.next(currencyCode);
  }

  set currencySymbolSet(currencySymbol: string) {
    this.currencySymbol$.next(currencySymbol);
  }

  get currencyCodeObs$(): Observable<string> {
    return this.currencyCode$.asObservable();
  }

  get currencySymbolObs$(): Observable<string> {
    return this.currencySymbol$.asObservable();
  }
}
