import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from './core/interceptors/auth-interceptor.service';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConfigurationLayoutComponent } from './layouts/config-layout/config-layout.component';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { FormsModule } from '@angular/forms';

import {SharedModule} from './shared/modules/primeng.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { RestartPasswordComponent } from './components/restart-password/restart-password.component';
import { AppBreadcrumbComponent } from './layouts/MainLayout/Components/BreadCrumbComponent/app.breadcrumb.component';
import { MainLayoutComponent } from './layouts/MainLayout/main-layout/main-layout.component';
import { AppMenuComponent } from './layouts/MainLayout/Components/MenuComponent/app.menu.component';

import { AppMenuitemComponent } from './layouts/MainLayout/Components/OthersComponents/app.menuitem.component';
import { AppRightMenuComponent } from './layouts/MainLayout/Components/RightMenuComponent/app.rightmenu.component';
import { MenuService } from './layouts/MainLayout/Services/app.menu.service';
import { AppBreadcrumbService } from './layouts/MainLayout/Services/app.breadcrumb.service';
import { HeaderCreditsComponent } from './layouts/MainLayout/Components/header-credits/header-credits.component';
import { ConfigurationGeneralComponent } from './pages/home/Configuration/configuration-general/configuration-general.component';
import { ConfigurationCreditsComponent } from './pages/home/Configuration/configuration-credits/configuration-credits.component';
import { ConfigurationVerificationComponent } from './pages/home/Configuration/configuration-verification/configuration-verification.component';
import { ConfigurationCasinoGeneralComponent } from './pages/home/Configuration/configuration-casino-general/configuration-casino-general.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
 

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


@NgModule({
  declarations: [
    AppComponent,
    ConfigurationLayoutComponent,
    AppLayoutComponent,
    NotFoundComponent,
    LanguageSelectorComponent,
    RestartPasswordComponent,
    AppBreadcrumbComponent,
    MainLayoutComponent,
    AppMenuComponent,
    AppMenuitemComponent,
    AppRightMenuComponent,
    HeaderCreditsComponent,
    ConfigurationGeneralComponent,
    ConfigurationCreditsComponent,
    ConfigurationVerificationComponent,
    ConfigurationCasinoGeneralComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    SharedModule,
    BrowserAnimationsModule,
    FormsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'es-MX',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),

  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,  useClass: AuthInterceptorService,
      multi: true


    }, MenuService,AppBreadcrumbService
  ],

  bootstrap: [AppComponent]
})
export class AppModule { }
