import { EventEmitter, Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderCreditsService {
  private creditReserveSubject = new Subject<number>();
  private profilePictureSubject = new Subject<string>();

  public reloadGLobalCountryComponent: EventEmitter<void> = new EventEmitter<void>();
  public disableGlobalCountryComponent: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  creditReserve = this.creditReserveSubject.asObservable();
  profilePicture = this.profilePictureSubject.asObservable();

  setCreditReserve(creditReserve: number) {
    this.creditReserveSubject.next(creditReserve);
  }
  
  setProfilePicture(profilePicture: string) {
    this.profilePictureSubject.next(profilePicture);
  }
  disableCountry(){
    this.disableGlobalCountryComponent.emit(true);
  }
  enableCountry(){
    this.disableGlobalCountryComponent.emit(false);
  }
}
