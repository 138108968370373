import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ButtonModule} from 'primeng/button';
import {CheckboxModule} from 'primeng/checkbox'
import {InputTextModule} from 'primeng/inputtext';
import {GalleriaModule} from 'primeng/galleria';
import {ToolbarModule} from 'primeng/toolbar';
import {ToastModule} from 'primeng/toast';
import {InputMaskModule} from 'primeng/inputmask'
import {RippleModule} from 'primeng/ripple';
import { MessageService } from 'primeng/api';
import { ChipModule } from 'primeng/chip';
import {CardModule} from 'primeng/card';
import {DataViewModule} from 'primeng/dataview';
import {RatingModule} from 'primeng/rating';
import {DropdownModule} from 'primeng/dropdown';
import {AvatarModule} from 'primeng/avatar'
import {MenuModule} from 'primeng/menu';
import { CalendarModule } from 'primeng/calendar';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DialogModule} from 'primeng/dialog';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {CarouselModule} from 'primeng/carousel';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {PasswordModule} from 'primeng/password';
import {DividerModule} from 'primeng/divider';
import {ChipsModule} from 'primeng/chips';
import {FileUploadModule} from 'primeng/fileupload';
import {HttpClientModule} from '@angular/common/http';
import {SidebarModule} from 'primeng/sidebar';
import { InputNumberModule } from 'primeng/inputnumber';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MultiSelectModule } from 'primeng/multiselect';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import { TableModule } from 'primeng/table';
import {SkeletonModule} from 'primeng/skeleton';
import {ImageModule} from 'primeng/image';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {ProgressBarModule} from 'primeng/progressbar';
import {BlockUIModule} from 'primeng/blockui';
import { TagModule } from 'primeng/tag';
import {PaginatorModule} from 'primeng/paginator';
import { ChartModule } from 'primeng/chart';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    TableModule,
    ButtonModule,
    CheckboxModule,
    InputTextModule,
    GalleriaModule,
    ToolbarModule,
    ToastModule,
    RippleModule,
    ChipModule,
    CardModule,
    DataViewModule,
    RatingModule,
    DropdownModule,
    InputMaskModule,
    AvatarModule,
    MenuModule,
    CalendarModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    MessagesModule,
    MessageModule,
    CarouselModule,
    ScrollPanelModule,
    PasswordModule,
    DividerModule,
    ChipsModule,
    FileUploadModule,
    HttpClientModule,
    SidebarModule,
    InputNumberModule,
    ConfirmDialogModule,
    InputSwitchModule,
    MultiSelectModule,
    BreadcrumbModule,
    SkeletonModule,
    ImageModule,
    ProgressSpinnerModule,
    ProgressBarModule,
    BlockUIModule,
    TagModule,
    PaginatorModule,
    ChartModule
  ],
  exports:
  [
    ButtonModule,
    TableModule,
    CheckboxModule,
    InputTextModule,
    GalleriaModule,
    ToolbarModule,
    ToastModule,
    RippleModule,
    ChipModule,
    CardModule,
    ToastModule,
    InputMaskModule,
    DataViewModule,
    RatingModule,
    DropdownModule,
    AvatarModule,
    MenuModule,
    CalendarModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    MessagesModule,
    MessageModule,
    CarouselModule,
    ScrollPanelModule,
    PasswordModule,
    DividerModule,
    ChipsModule,
    FileUploadModule,
    HttpClientModule,
    SidebarModule,
    InputNumberModule,
    ConfirmDialogModule,
    InputSwitchModule,
    MultiSelectModule,
    BreadcrumbModule,
    SkeletonModule,
    ImageModule,
    ProgressSpinnerModule,
    ProgressBarModule,
    BlockUIModule,
    TagModule,
    PaginatorModule,
    ChartModule
  ],
  providers: [MessageService]

})
export class SharedModule { }
