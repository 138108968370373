import { Component, OnInit, AfterContentInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { SessionService } from './core/auth/session.service';
import { ApiService } from './core/http/api.service';
import { filter, map, mergeMap } from "rxjs/operators";
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit  {
   menuMode = 'sidebar';

    layout = 'blue';

    theme = 'blue';

    ripple!: boolean;

    colorScheme = 'dark';

    subscription: Subscription;

    constructor(
      private primengConfig: PrimeNGConfig,
      private api: ApiService,
      private session: SessionService,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private titleService: Title,
      public translate: TranslateService
      ) {
        this.dynamicTitle();

        translate.addLangs(['es-MX']);
        translate.setDefaultLang('es-MX');

        this.subscription = this.translate.stream('primeng').subscribe(data => {
          this.primengConfig.setTranslation(data);
        });
      }


    async ngOnInit():  Promise<void> {
        this.primengConfig.ripple = true;
        this.ripple = true;
        //await this.getOperatorConfig();
        //await this.getOne();
    }


    dynamicTitle = () => {
      this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === "primary"),
        mergeMap(route => route.data)
      )
      .subscribe(event => {
        this.titleService.setTitle(
          `AIC Online ${
            event["title"] ? " | " + event["title"] : ""
          }`
        )
      }  
      );
    }

    getOperatorConfig(): Promise<any> {
      return new Promise((resolve, reject) => {
        this.api.GetOperatorConfig().subscribe(res => {
          this.session.setItem("operatorLogo", res.data.logoUrl);
        });
      });
    }


  async getOne() {
    try {
      //this.imgUrl = (await this.testService.get('mewtwo')).sprites.back_shiny;
      await this.api.GetOperatorConfig().subscribe(res => {
        this.session.setItem("operatorLogo", res.data.logoUrl);
      });
    } catch (error) {
      alert('Error happened');
      console.error(error);
    }
  }
}
