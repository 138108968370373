import { Component,/* OnDestroy, OnInit*/ } from '@angular/core';
import { filter, map, mergeMap } from "rxjs/operators";
import { AppBreadcrumbService } from '../../Services/app.breadcrumb.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './app.breadcrumb.component.html',
})
export class AppBreadcrumbComponent {

  public breadcrumbs: { label: string, routerLink?: string }[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public breadcrumbService: AppBreadcrumbService,
    //public appMenu: AppMenuComponent
  ) {
    this.dynamicBreadcrumb();
  }

  dynamicBreadcrumb = () => {
    this.router.events
    .pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter(route => route.outlet === "primary"),
      mergeMap(route => route.data)
    )
    .subscribe(event => {
      this.breadcrumbs = event['breadcrumb']
    }  
    );
  }
}
