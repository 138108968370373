import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ApiService } from '../../../../core/http/api.service';
import { EventService } from '../services/utc.service';

@Component({
  selector: 'app-configuration-general',
  templateUrl: './configuration-general.component.html',
  styleUrls: ['./configuration-general.component.css'],
  providers: [MessageService],
})
export class ConfigurationGeneralComponent implements OnInit {
  @Output() messageEvent = new EventEmitter<boolean>();
  showConfirmSaveDialog: boolean = false;
  configId: string = '';
  logo: any = null;
  image: any;
  isButtonDisabled: boolean = true;
  showProgressBar: boolean = false;
  countries_code_catalog: any[] = [];
  messaging_services_catalog: any[] = [];
  timezone_catalog: any[] = [];
  language_catalog: any[] = [];

  defaultValues = {
    timezone: 'clawpkldr000suro8yqdjfb6v',
    language: 'clawoq96w0000uro8rexpohqd',
    logo: null,
  };

  showLoadSkeleton: boolean = false;
  incomplete: boolean = false;
  isSaved: boolean = true;
  // public selectedCountry: any =   //{ id:'clabcdo4k000yur7c0eem0noj', country: 'México', ladaCode: '(+52)', flagCode: 'mx' };
  // {
  //   id: "clabcdo4k000yur7c0eem0noj",
  //   country: "México",
  //   ladaCode: "(+52)",
  //   flagCode: "mx",
  //   isActive: true,
  //   createdAt: "2022-11-10T17:26:30.493Z",
  //   updatedAt: "2022-11-10T17:23:12.321Z"
  // }

  // public selectedTimezone: any = {
  //   id: "clawpkldr000suro8yqdjfb6v",
  //   name: "UTC-06:00",
  //   description: "UTC-06:00",
  //   isActive: true,
  //   createdAt: "2022-11-25T16:18:58.238Z",
  //   updatedAt: "2022-11-25T16:15:02.419Z"
  // }

  // selectedLanguage: any = {
  //   id: "clawoq96w0000uro8rexpohqd",
  //   name: "Español",
  //   description: "Español",
  //   isActive: true,
  //   createdAt: "2022-11-25T15:55:22.760Z",
  //   updatedAt: "2022-11-25T15:54:54.287Z"
  // }

  public generalForm!: FormGroup;
  private projectId: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private api: ApiService,
    private messageService: MessageService,
    private activRoute: ActivatedRoute,
    private eventService: EventService
  ) {
    const incomplete = this.activRoute.snapshot.queryParamMap.get('incomplete');
    if (incomplete) {
      this.incomplete = true;
    }

    this.generalForm = this.formBuilder.group({
      timezone: [this.defaultValues.timezone, [Validators.required]],
      language: [this.defaultValues.language, [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.getTimezoneCatalog();
    this.getLanguageCatalog();
    this.loadUserConfig();
    this.getLogCreteProjectInfo();
    // this.getOperatorConfig();
  }

  getOperatorConfig() {
    this.showLoadSkeleton = true;

    this.api.list('operator/operator-config').subscribe((res) => {
      //default values from ep
      this.defaultValues.timezone = res.data.timeZoneCatId;
      this.defaultValues.language = res.data.languageCatId;
      this.defaultValues.logo = res.data.logoUrl;

      //set Values
      this.configId = res.data.id;
      this.logo = res.data.logoUrl;
      this.generalForm.get('timezone')?.setValue(res.data.timeZoneCatId);
      this.generalForm.get('language')?.setValue(res.data.languageCatId);
      setTimeout(() => {
        this.showLoadSkeleton = false;
      }, 750);
    });
  }

  private getLogCreteProjectInfo(): void {
    this.api.list('project/log-create-project').subscribe({
      next: (res) => {
        this.projectId = res.data.projectId;
      },
      error: (e) => {
        console.log(e);
      },
    });
  }

  getTimezoneCatalog() {
    this.showLoadSkeleton = true;
    this.api
      .list('project/timezone-catalog?isActive=1')
      .subscribe((response) => {
        this.timezone_catalog = response.data;
        // setTimeout(() => {
        //   this.showLoadSkeleton = false;
        // }, 750);
      });
    this.showLoadSkeleton = false;
  }

  getLanguageCatalog() {
    this.showLoadSkeleton = true;
    this.api.list('project/language-catalog?isActive=1').subscribe((res) => {
      this.language_catalog = res.data;
    });
    this.showLoadSkeleton = false;
  }

  changeSelect(field: string) {
    switch (field) {
      case 'timezone':
        const desiredId = this.generalForm.get('timezone')?.value;
        const selectedTimeZone = this.timezone_catalog.find(
          (timeZone) => timeZone.id === desiredId
        );
        const utcOffsetString = selectedTimeZone.description;
        const match = utcOffsetString.match(/UTC([+-]\d+):(\d+)/);
        if (match) {
          const utcOffsetHours = parseInt(match[1], 10);
          const formattedDate =
            this.getFormattedDateWithUtcOffset(utcOffsetHours);
          // this.generalForm.get("current_date")?.setValue(formattedDate);
        } else {
          console.error('UTC no valido');
        }
        break;
      case 'language':
        console.log(field);
        break;
      default:
        return;
        break;
    }
    if (this.generalForm.valid) {
      this.isButtonDisabled = false;
      if (
        this.defaultValues.timezone ===
          this.generalForm.get('timezone')?.value &&
        this.defaultValues.language === this.generalForm.get('language')?.value
      ) {
        this.isButtonDisabled = true;
      }
    }
  }

  saveImage = (data: any) => {
    this.showProgressBar = true;
    this.api
      .uploadOperatorLogo('operator/upload-logo-img', data)
      .subscribe((response) => {
        if (response.statusCode === 200) {
          this.showProgressBar = false;
          this.showConfirmSaveDialog = false;
          setTimeout(() => {
            this.messageService.add({
              severity: 'success',
              summary: 'Cambios aplicados satisfactoriamente',
            });
          }, 300);
          this.getOperatorConfig();
          this.dataSaved();
          this.isButtonDisabled = true;
        }
      });
  };

  dataSaved = () => {
    this.messageEvent.emit(this.isSaved);
  };

  save() {
    this.showConfirmSaveDialog = true;
    if (!this.generalForm.valid) return;
    const data = {
      user: {
        timeZoneCatId: this.generalForm.get('timezone')?.value,
        languageCatId: this.generalForm.get('language')?.value,
      },
      projectId: this.projectId,
    };
    // const imageUpload = {
    //   file: this.image,
    //   id: this.configId
    // }
    this.showProgressBar = true;
    this.api.updateDirect('project/user-config', data).subscribe(
      (res) => {
        if (res.statusCode === 201) {
          this.messageService.add({
            severity: 'success',
            summary: 'Cambios aplicados satisfactoriamente',
          });
          this.isButtonDisabled = true;
          this.eventService.reloadSellComponent.emit();
          this.defaultValues.language = this.generalForm.get('language')?.value;
          this.defaultValues.timezone = this.generalForm.get('timezone')?.value;

          const data = {
            timeZoneCatId: this.generalForm.get('timezone')?.value,
            languageCatId: this.generalForm.get('language')?.value,
          };
        }
        //console.log({res})
        this.showProgressBar = false;
        this.showConfirmSaveDialog = false;
      },
      (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'ERROR: request failed',
        });
        this.showProgressBar = false;
        this.showConfirmSaveDialog = false;
        console.log('ERROR', err);
      }
    );

    /*if(this.generalForm.get("messaging_services")?.dirty) {
      console.log(this.generalForm.get("messaging_services")?.value);
      // for each messaging service on messaging_services create a new post request
      this.generalForm.get("messaging_services")?.value.forEach((element: any) => {
        const data = {
          operatorConfigId: this.configId,
          messagingServiceId: element,
        }

        this.api.create('operator/operator-messaging-services', data).subscribe((res) => {
          console.log(res);
        }, (err) => {
          console.log(err);
        });
      });
    }*/
  }

  closeDialog = () => {
    this.showConfirmSaveDialog = false;
    !this.incomplete ? document.body.classList.add('p-overflow-hidden') : '';
  };

  showDialog = () => {
    // this.isButtonDisabled ? this.showConfirmSaveDialog = false : this.showConfirmSaveDialog = true;
    this.showConfirmSaveDialog = !this.isButtonDisabled;
  };

  loadLogo() {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.png, .jpeg, .jpg';
    input.onchange = (e: any) => {
      const file = e.target.files[0];
      this.image = file;
      if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
        this.messageService.add({
          key: 'bc',
          severity: 'error',
          summary: 'Formato de archivo no permitido',
        });
        return;
      }
      if (file.size > 1000000) {
        this.defaultValues.logo === null
          ? 'assets/images/no-photo-available.png'
          : this.defaultValues.logo;
        this.messageService.add({
          key: 'bc',
          severity: 'error',
          summary: 'La imagen excede el límite de peso',
        });
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.logo = reader.result;
        this.messageService.add({
          severity: 'success',
          summary: 'Imagen cargada, no olvides guardar tus cambios',
        });
        this.isButtonDisabled = false;
      };
    };
    input.click();
  }

  getFormattedDateWithUtcOffset(utcOffsetHours: number): string {
    const currentDate = new Date();
    const utcTime = currentDate.getTime() + currentDate.getTimezoneOffset();
    const adjustedTime = utcTime + utcOffsetHours * 60 * 60 * 1000;
    const adjustedDate = new Date(adjustedTime);
    const year = adjustedDate.getUTCFullYear();
    const month = (adjustedDate.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = adjustedDate.getUTCDate().toString().padStart(2, '0');
    const hours = adjustedDate.getUTCHours().toString().padStart(2, '0');
    const minutes = adjustedDate.getUTCMinutes().toString().padStart(2, '0');
    const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;

    return formattedDate;
  }

  private loadUserConfig() {
    this.showLoadSkeleton = true;

    this.api.list('user/user-config').subscribe((res) => {
      //default values from ep
      this.defaultValues.timezone = res.data.timeZoneCatId;
      this.defaultValues.language = res.data.languageCatId;
      // this.defaultValues.logo = res.data.logoUrl;

      //set Values
      // this.configId = res.data.id;
      // this.logo = res.data.logoUrl;
      this.generalForm.get('timezone')?.setValue(res.data.timeZoneCatId);
      this.generalForm.get('language')?.setValue(res.data.languageCatId);
    });
    this.showLoadSkeleton = false;
  }
}
