import { formatDate } from "@angular/common";

export class Utilities {

    // checks if an object contains null or empty fields, and if the object contains an array checks if it's empty and return true or false
    public static hasNull (data: any): boolean {
        let output = false;
        for (const key in data) {
            if(data[key] === null || data[key] === undefined || data[key].length === 0){
                output = true;
            }
        }
        return output;
    }

    public static Calendar_ES = {
        firstDayOfWeek: 1,
        dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
        dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
        dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
        monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
        monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        today: 'Hoy',
        clear: 'Limpiar',
    }

    public static formatDateDataBase(dateToDB: Date) {
        return formatDate(dateToDB, "yyyy-MM-dd", "es-MX");
    }

    public static formatDateDMY(dateDMY: Date) {
        return formatDate(dateDMY, "dd/MM/yyyy", "es-MX");
    }

    public static getFirstNLastMonthDay = (monthNumber: number, year: number) => {
        const date = new Date();
        const currentYear = year === 0 ? date.getFullYear() : year;
        date.setFullYear(currentYear, monthNumber - 1, 1);
        const firstDay = new Date(year === 0 ? date.getFullYear() : year, date.getMonth(), 1);
        const lastDay = new Date(year === 0 ? date.getFullYear() : year, date.getMonth() + 1, 0);
        return { firstDay, lastDay };
    }

    public static getLast12Months = () => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;

        const monthNames = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];

        const last12Months: { month: number; monthName: string; year: number }[] = [];

        for (let i = 0; i < 12; i++) {
            const month = currentMonth - i;
            const year = currentYear - (month <= 0 ? 1 : 0);
            const adjustedMonth = month <= 0 ? month + 12 : month;
            const monthName = monthNames[adjustedMonth - 1]; 
            last12Months.unshift({ month: adjustedMonth, monthName, year });
        }

        return last12Months;
    }
}


