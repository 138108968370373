<div style="margin-right: 20px;" class="flex flex-row align-items-center ">
<p-toast position="top-center" key="countryChanged"></p-toast>

  <!--
  <div *ngIf="!hideCreditReserve">
    <div [routerLink]="'/app/credit-reserve'" class="border-round h-4rem shadow-4" style="cursor:pointer; background-color: '#FFD400'; color: black; padding: 0px 0px;" >
      <div class="flex p-3" >
        <div class="flex align-items-center max-w-10rem w-10rem min-w-max">
          <div  style="margin-right: 0px !important;">
            <img [src]="'../assets/images/money.png'" style='width: 50%;'>
          </div>
          <div class="grid p-fluid">
              <div class="p-inputgroup">
                  <span style="font-weight: bold; font-size: 16px;"><small>{{'main.my_credits' | translate}}</small></span>
              </div>
              <div class="p-inputgroup">
                  <span><strong>{{creditReserve | number}}</strong> </span>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>-->
  <div class="hidden sm:block">
      <form [formGroup]="myFormCountry" class="min-w-10rem w-12rem max-w-12rem">
        <div class="label-form"><strong>País</strong></div>
        <p-skeleton *ngIf="showLoadSkeleton" shape="rectangle" height="2.5rem"></p-skeleton>
        <p-dropdown *ngIf="!showLoadSkeleton" [disabled]="disabledCountry" (ngModelChange)="setGlobalCountryBase($event)" formControlName="country" [options]="listCountries" optionLabel="country" optionValue="id" [filter]="false" filterBy="country"
        [showClear]="false" placeholder="Seleccione un país">
            <ng-template pTemplate="selectedItem" let-option>
                <div class="country-item country-item-value flex">
                    <img src="assets/images/flag/flag_placeholder.png" [class]="'flag flag-' + option.flagCode.toLowerCase()" />
                    <div class="ml-2">{{option.country}} {{option.ladaCode}}</div>
                </div>
            </ng-template>
            <ng-template let-country pTemplate="item">
                <div class="country-item flex">
                    <img src="assets/images/flag/flag_placeholder.png" [class]="'flag flag-' + country.flagCode.toLowerCase()" />
                    <div class="ml-2">{{country.country}} {{country.ladaCode}}</div>
                </div>
            </ng-template>
        </p-dropdown>
      </form>
  </div>
  <!-- DISEÑO PARA CELULARES -->
  <div class="block  sm:hidden">
    <form [formGroup]="myFormCountry" class="min-w-6rem w-6rem max-w-6rem">
      <p-skeleton *ngIf="showLoadSkeleton" shape="rectangle" height="2.5rem"></p-skeleton>
      <p-dropdown *ngIf="!showLoadSkeleton" (ngModelChange)="setGlobalCountryBase($event)" formControlName="country" [options]="listCountries" optionLabel="country" optionValue="id" [filter]="false" filterBy="country"
      [showClear]="false" placeholder="Seleccione un país">
          <ng-template pTemplate="selectedItem" let-option>
              <div class="country-item country-item-value flex">
                  <img src="assets/images/flag/flag_placeholder.png" [class]="'flag flag-' + option.flagCode.toLowerCase()" />
              </div>
          </ng-template>
          <ng-template let-country pTemplate="item">
              <div class="country-item flex">
                  <img src="assets/images/flag/flag_placeholder.png" [class]="'flag flag-' + country.flagCode.toLowerCase()" />
              </div>
          </ng-template>
      </p-dropdown>
    </form>
  </div>
  <div class="mt-1">
    <p-menu styleClass="w-13rem" #menu [popup]="true" [model]="items"></p-menu>
    <a [style]="{'width':'70px','padding-left':'0px', 'background': 'transparent', 'border':'1px solid transparent','box-shadow':'0px 0px 0px rgb(15 139 253 / 30%)', 'margin-left':'10px'}"  pButton icon="pi pi-angle-down" (click)="menu.toggle($event)">
      <p-avatar
        pBadge
        size="large"
        shape="circle"
        image={{profilePicture}}>
      </p-avatar>
    </a>
  </div>
</div>
<p-sidebar [(visible)]="displaySidebar" [blockScroll]="true" [fullScreen]="true" [transitionOptions]="'50ms cubic-bezier(0, 0, 0.2, 1)'" [showCloseIcon]="false" (onHide)="onCloseConfig()">
    <div class="flex w-full justify-content-between align-items-center">
      <div class="flex ml-3 text-4xl align-items-center font-bold " style="font-size: 1.875rem;">
        <i class='{{iconSidebar}} text-4xl pr-2'></i>
        {{titleSidebar}}
      </div>
      <div class="flex flex-row closeButton mr-3 cursor-pointer text-xl" (click)="closeConfig()">
        <span class="mr-1">Cerrar</span>
        <div class="ml-1" style="margin-top: 2px;">
          <i class="pi pi-times text-xl"></i>
        </div>
      </div>
    </div>
    <p-divider></p-divider>
    <app-config-layout *ngIf="renderComponent"></app-config-layout>
</p-sidebar>
<p-dialog header="Header" [(visible)]="alertIncompleteData" [closable]="false" [modal]="true" [style]="{width: '500px'}"> 
  <p-header >
    <div style="justify-content: left; flex-direction: row; display: flex; align-items: left;">
      <i class="pi pi-info-circle" style="font-size: 2rem; padding-top: 5px;"></i>
      <label class="header_title" >Completar configuración</label>
    </div>
  </p-header>
  <div style="justify-content: left; display: flex; flex-wrap: wrap;">
    <span>Es necesario completar la configuración para poder continuar.</span>
  </div>
  <p-footer>
    <p-button icon="pi pi-check" (click)="goToConfig(isIncomplete)" label="Aceptar" styleClass="p-button-text"></p-button>
  </p-footer>
</p-dialog>


