import { environment } from "src/environments/environment";

export class ApiCore {
  public static BASE_URL = `${environment.BaseURL}`;

  // Authentication
  public static AUTH = 'auth/';
  public static LOGIN = 'login/';
  public static SIGNIN = 'signin';
  public static SIGNIUP = 'signup/';
  public static VERIFY = 'verify/';
  public static USER = 'user';
  public static PLAYER = 'player';
  public static OPERATOR = 'operator';
  public static RECOVER_PASSWORD = 'password-reset-request';
  public static RESET_PASSWORD = 'password-reset';
  public static USER_UPLOAD_PERSONAL_ID = 'user/upload-personal-id';
  public static USER_UPLOAD_IMAGE_PROFILE = 'user/upload-profile-img';
  public static OPERATOR_UPLOAD_IMAGE_PROFILE = 'operator/upload-profile-img';
  public static LOBBY_OPEN_GAMES = 'lobby-open/games';
  public static LOBBY_OPEN_SECTIONS = '/lobby-open/custom-sections/';
  public static LOBBY_OPEN_BANNERS = '/lobby-open/banners/';
  public static ENTER_GAMES = 'game/enterGame/';
  public static CATALOGS = 'catalogs/';
  public static DASHLET = 'dashlet/';
  public static EMAIL_AVAILABLE = 'email-availability/';
  public static USER_AVAILABLE = 'user-name-availability/';
  // Generic API
  public static API = 'user';
  public static API_VERSION = '';
  // Special services API
  public static SPECIAL = '';

  // Configuration
  public static MESSAGING_SERVICES = 'operator/operator-messaging-services';

  // Create project
  public static PROJECT = 'project/';
  // Project information
  public static PROJECT_INFO = 'operator/project/';
}
